import Card from '@/components/Card/Card.vue';
import Btn from '@/components/Button/Button.vue';
import { Icon, IconSize } from '@/components/Icon';
import Tabs from '@/components/Tabs/Tabs.vue';
import Tab from '@/components/Tabs/Tab.vue';
import UploadList from '@/components/Uploader/UploadList.vue';
import SplitLayout from '@/layouts/SplitLayout.vue';
import ClientsBrandsSidebar from '@/views/Dashboard/Clients/ClientsBrandsSidebar.vue';
import FetchData from '@/components/DataProvider/FetchData.vue';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import { ImageView, ImageSize } from '@/components/ImageView';
import VueStore from '@/store';
import ModalWrapper from '@/components/Modal/ModalWrapper.vue';
import FilterToggler from '@/components/Filters/FilterToggler.vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useClient, useMobileView } from '@/composables';
import { useAttachment } from '@/composables/use-attachment';
import { useRouteQuery, useConfirmModal } from '@/composables';
import { deleteOrganization } from '@/api/requests/organizations';
import { deleteBrand } from '@/api/requests/brand';
import { FILTER_DEBOUNCE } from '@/constants/filters';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { mdiChevronLeft, mdiHomeOutline } from '@mdi/js';
import EditSvg from '@/assets/icons/edit.svg';
import DeleteSvg from '@/assets/icons/delete.svg';
import FolderSvg from '@/assets/icons/folder.svg';
import WithSideNavigations from '@/layouts/WithSideNavigations.vue';
const { useGetters } = createNamespacedHelpers('user');
export default defineComponent({
    name: 'ClientDetails',
    components: {
        Btn,
        Card,
        Icon,
        Tabs,
        Tab,
        SplitLayout,
        UploadList,
        ClientsBrandsSidebar,
        FetchData,
        IconSpinner,
        ModalWrapper,
        FilterToggler,
        ImageView,
        WithSideNavigations,
    },
    async beforeRouteEnter(to, from, next) {
        const promises = [
            VueStore.dispatch('client/fetchClientDetails', to.params['id']),
        ];
        if (VueStore.getters['user/hasAdminRights']) {
            promises.push(VueStore.dispatch('attachment/fetchAttachments', {
                id: to.params['id'],
                target: 'organizations',
                type: 'document',
            }));
        }
        await Promise.all(promises);
        next();
    },
    setup(_, ctx) {
        const { client } = useClient();
        const { attachments } = useAttachment(ctx);
        const { routeQuery } = useRouteQuery(ctx);
        const { getConfirmModalObject } = useConfirmModal(ctx);
        const { hasAdminRights } = useGetters(['hasAdminRights']);
        const { isMobileView } = useMobileView();
        const sidebarOpen = ref(false);
        const brandDeletionInProgress = ref(false);
        const listUpdatesCounter = ref(0);
        const computedQuery = computed(() => {
            return {
                ...routeQuery.value,
                clientId: client.value.id,
            };
        });
        const onBrandEdit = (id) => {
            ctx.root.$router.push({ name: 'EditBrand', params: { brandId: id } });
        };
        const onBrandDelete = async (id) => {
            try {
                brandDeletionInProgress.value = true;
                await deleteBrand(id);
                listUpdatesCounter.value++;
            }
            finally {
                brandDeletionInProgress.value = false;
                ctx.root.$toast.success(ctx.root.$t('client-details-view.brand-deleted-successfully'));
            }
        };
        const onClientDelete = async () => {
            try {
                await deleteOrganization(client.value.id);
                ctx.root.$toast.success(ctx.root.$t('clients-view.deleted-successfully'));
            }
            finally {
                ctx.root.$router.push({ name: 'Clients' });
            }
        };
        return {
            IconSize,
            mdiChevronLeft,
            mdiHomeOutline,
            EditSvg,
            DeleteSvg,
            FolderSvg,
            FILTER_DEBOUNCE,
            ImageSize,
            attachments,
            client,
            listUpdatesCounter,
            brandDeletionInProgress,
            hasAdminRights,
            sidebarOpen,
            computedQuery,
            isMobileView,
            onBrandEdit,
            onBrandDelete,
            onClientDelete,
            getConfirmModalObject,
        };
    },
});
