import api from '@/api';
export const deleteBrand = async (brandId) => {
    await api.delete(`/brands/${brandId}`);
};
export const getBrandsList = async (params) => {
    const { data } = await api.get('brands', {
        params,
    });
    return data;
};
export const updateBrand = async (brandId, payload) => {
    const { data } = await api.patch(`brands/${brandId}`, payload);
    return data;
};
